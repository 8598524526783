import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDrivers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/drivers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDriver(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/driver/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDriver(ctx, { id,body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`admin/driver/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDriver(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/driver/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
