import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function driverHandlerSidebar(props, emit) {
  // ------------------------------------------------
  // serviceCategoryLocal
  // ------------------------------------------------
  const objetLocal = ref(JSON.parse(JSON.stringify(props.driver.value)))

  const resetLocal = () => {
    objetLocal.value = JSON.parse(JSON.stringify(props.driver.value))
  }
  watch(props.driver, () => {
    resetLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const postCategoryData = JSON.parse(JSON.stringify(objetLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.driver.value.id) emit('update-driver', objetLocal.value)
    else emit('add-driver', objetLocal.value)

    // Close sidebar
    emit('update:is-driver-handler-sidebar-active', false)
  }

  return {
    objetLocal,
    resetLocal,

    // UI

    onSubmit,
  }
}
