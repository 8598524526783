<template>
  <div class="">
    <driver-sidebar
      v-model="isDriverHandlerSidebarActive"
      :driver="driver"
      :clear-data="clearData"
      @add-driver="addDriver"
      @update-driver="updateDriver"
    />
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="7"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de conductores</h3> <small> (Hay {{ totalDrivers }} conductores)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
              <b-button
                variant="primary"
                @click="isDriverHandlerSidebarActive = true"
              >
                Crear
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refDriverListTable"
        :items="fetchDrivers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        class="position-relative"
      >
        <!-- Column: Id -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mx-1"
              size="16"
              @click="editDriver(data.item)"
            />
            <b-tooltip
              title="Editar"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer"
              @click="deleteDriver(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`invoice-row-${data.item.id}-delete-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!--        <b-col-->
          <!--          cols="12"-->
          <!--          sm="6"-->
          <!--          class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
          <!--        >-->
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          <!--        </b-col>-->
          <!-- Pagination -->

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol, BForm, BFormGroup,
  BFormInput,
  BMedia, BPagination,
  BRow,
  BTable, BTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required, url, alpha, alphaDash,
} from '@validations'
import { onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import driverStoreModule from '@/views/dramox/guides/driver/driverStoreModule'
import DriverSidebar from '@/views/dramox/guides/driver/sidebar/driverSidebar'

export default {
  name: 'HomeBusiness',
  components: {
    DriverSidebar,
    BCard,
    BRow,
    BCol,
    BTable,
    BTooltip,
    BButton,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-guide-drivers'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, driverStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const isDriverHandlerSidebarActive = ref(false)
    const refDriverListTable = ref(null)
    const totalDrivers = ref(0)

    const blankDriver = {
      name: '',
      nroLicense: '',
    }
    const driver = ref(JSON.parse(JSON.stringify(blankDriver)))
    const clearData = () => {
      driver.value = JSON.parse(JSON.stringify(blankDriver))
    }

    const tableColumns = [
      {
        key: 'name',
        label: 'Nombre',
        sortable: false,
      },
      {
        key: 'nroLicense',
        label: 'N° Licencia',
        sortable: false,
      },
      {
        key: 'actions',
        label: 'Acciones',
      },
    ]

    const refetchData = () => {
      refDriverListTable.value.refresh()
    }

    const editDriver = item => {

      driver.value = item
      isDriverHandlerSidebarActive.value = true
    }
    const addDriver = item => {
      store.dispatch('app-guide-drivers/addDriver', { body: item })
        .then(() => {
          refetchData()
          isDriverHandlerSidebarActive.value = false
          showNotification({ title: 'Conductor creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const updateDriver = item => {
      store.dispatch('app-guide-drivers/updateDriver', { id: item.id, body: item })
        .then(() => {
          refetchData()
          isDriverHandlerSidebarActive.value = false
          showNotification({ title: 'Conductor actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteDriver = item => {
      store.dispatch('app-guide-drivers/deleteDriver', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Conductor eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const fetchDrivers = (ctx, callback) => {
      store
        .dispatch('app-guide-drivers/fetchDrivers')
        .then(response => {
          const { results } = response.data.payload

          callback(results)
          totalDrivers.value = results.length
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching categories list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      isDriverHandlerSidebarActive,
      driver,

      fetchDrivers,
      tableColumns,
      refDriverListTable,
      totalDrivers,

      refetchData,

      clearData,
      editDriver,
      addDriver,
      updateDriver,
      deleteDriver,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
